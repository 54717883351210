<template>
  <div class="iss-wrapper">
    <a-row :gutter="16">
      <a-col :span="8">
        <div class="main-tree">
          <div class="flex mb-10">
            <a-input-search
              class="mr-8"
              placeholder="请输入名称"
              v-model:value="moduleCode.moduleCode"
              @blur="handleChange"
            />
            <a-space :size="8">
              <a-button type="primary" @click="handleClickAdd">
                <template #icon>
                  <component :is="icons['PlusOutlined']" />
                </template>
                新增
              </a-button>
              <a-button @click="handleClickDelete">
                <component
                  :is="icons['DeleteOutlined']"
                  style="font-size: 15px"
                />
                删除
              </a-button>
            </a-space>
          </div>
          <!--        语言切换   -->
          <div class="language">
            <a-radio-group
              v-model:value="form.language"
              :options="languageList"
              @change="handleChange"
            />
          </div>
          <!-- <span>日程分类</span> -->
          <a-spin :spinning="spinning">
            <a-tree
              class="iss-tree"
              check-strictly
              block-node
              v-model:expanded-keys="expandedKeys"
              v-model:checked-keys="checkedKeys"
              :selected-keys="selectedKeys"
              :tree-data="treeData"
              :replace-fields="{
                children: 'children',
                title: 'categoryName',
                key: 'id',
              }"
              @select="handleSelect"
              @expand="handleExpand"
            >
              <template #title="{ categoryName }">
                <div class="iss-tree-item">
                  <span class="flex-1">{{ categoryName }}</span>
                </div>
              </template>
            </a-tree>
            <!-- <a-tree
              class="iss-tree"
              check-strictly
              block-node
              v-model:expanded-keys="expandedKeys"
              v-model:checked-keys="checkedKeys"
              :selected-keys="selectedKeys"
              :auto-expand-parent="false"
              :tree-data="treeNewsData"
              :replace-fields="{
                children: 'children',
                title: 'categoryName',
                key: 'id',
              }"
              @select="handleSelect"
            >
              <template #title="{ categoryName }">
                <div class="iss-tree-item">
                  <span class="flex-1">{{ categoryName }}</span>
                </div>
              </template>
            </a-tree>
            <template v-if="treeDataBaseData.length > 0">
              <a-tree
                class="iss-tree"
                check-strictly
                block-node
                v-model:expanded-keys="expandedKeys"
                v-model:checked-keys="checkedKeys"
                :selected-keys="selectedKeys"
                :auto-expand-parent="false"
                :tree-data="treeDataBaseData"
                :replace-fields="{
                  children: 'children',
                  title: 'categoryName',
                  key: 'id',
                }"
                @select="handleSelect"
              >
                <template #title="{ categoryName }">
                  <div class="iss-tree-item">
                    <span class="flex-1">{{ categoryName }}</span>
                  </div>
                </template>
              </a-tree>
            </template> -->
          </a-spin>
        </div>
      </a-col>
      <a-col :span="16">
        <div class="main-content">
          <div class="parent">
            <div class="left">
              <span>父级：</span>
              <span>{{ form.parentName }}</span>
            </div>
            <div class="right">
              <span>语言：</span>
              <!--              todo 中文字段得更改     -->
              <span>{{ form.language === 'English' ? '英文' : '中文' }}</span>
            </div>
          </div>

          <a-form class="iss-form" :wrapper-col="{ span: 18 }">
            <a-form-item label="分类名称" v-bind="validateInfos.categoryName">
              <a-input v-model:value="form.categoryName" />
            </a-form-item>
            <a-form-item label="是否可用" v-bind="validateInfos.status">
              <a-switch
                v-model:checked="form.status"
                checked-children="是"
                un-checked-children="否"
                @click="!form.status"
              />
            </a-form-item>

            <a-form-item label="排序" v-bind="validateInfos.sort">
              <a-input-number v-model:value="form.sort" :min="1" :max="99" />
            </a-form-item>
            <a-form-item>
              <a-button class="iss-btn" type="primary" @click="handleSave"
                >保存</a-button
              >
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
  <a-modal
    title="新增"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    :visible="visibleModal"
  >
    <div>
      <div class="parent">
        <div class="left">
          <span>父级：</span>
          <span>{{ formData.parentName }}</span>
        </div>
        <div class="right">
          <span>语言：</span>
          <!--              todo 中文字段得更改     -->
          <span>{{ formData.language === 'English' ? '英文' : '中文' }}</span>
        </div>
      </div>

      <a-form :wrapper-col="{ span: 18 }" ref="formRef" :model="formData">
        <a-form-item label="分类名称" v-bind="validateInfos.categoryName">
          <a-input v-model:value="formData.categoryName" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <icon-modal
    v-model:visible="visible"
    :init-value="form.icon"
    @fnOk="value => (form.icon = value)"
  />
</template>

<script>
import { createVNode, reactive, ref, toRaw, toRefs } from 'vue';
// import { useRoute } from 'vue-router';
import {
  Col,
  // Dropdown,
  Form,
  InputNumber,
  // Menu,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tree,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import IconModal from '@/components/iconModal';
import IssResources from '@/components/resources';
import { getTreeItem } from '@/utils';
import menuApi from '@/api/menu.js';
// import classificationApi from '@/api/classification.js';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ASpin: Spin,
    ATree: Tree,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    ASelect: Select,
    ASelectOption: Select.Option,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    // ADropdown: Dropdown,
    // AMenu: Menu,
    // AMenuItem: Menu.Item,
    IconModal,
    AModal: Modal,
    IssResources,
  },
  setup() {
    // const route = useRoute();
    const state = reactive({
      spinning: false,
      visibleModal: false,
      treeData: [],
      treeMeetingData: [],
      treeNewsData: [],
      treeDataBaseData: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: { checked: [] },
      parentId: '',
      languageList: [
        { label: '中文', value: 'Chinese' },
        { label: '英文', value: 'English' },
      ],
      formData: {},
    });
    const moduleCode = reactive({
      moduleCode: '',
      language: 'Chinese',
    });
    const activeKey = ref('one');
    const componentType = ref('1');
    const visible = ref(false);
    const formRef = ref();
    const form = reactive({
      id: '',
      parentId: 0,
      parentName: '根结点',
      categoryName: '',
      status: false,
      sort: '',
      language: 'Chinese',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      categoryName: [
        required,
        { max: 50, message: '分类名称不能超过50个字符' },
      ],
    });
    let cacheTrees = [];

    const initTreeData = () => {
      state.spinning = true;
      moduleCode.language = form.language;
      menuApi
        .getList('', moduleCode)
        .then(data => {
          cacheTrees = data.meeting;
          if (data.news?.length > 0) {
            cacheTrees.concat(data.news);
            state.treeNewsData = [
              {
                categoryName: '新闻分类',
                children: data.news,
                moduleCode: 'news',
                language: data.meeting[0].language,
                parentId: null,
                id: 2,
              },
            ];
          }
          // if (data.dataBase?.length > 0) {
          cacheTrees.concat(data.dataBase);
          state.treeDataBaseData = [
            {
              categoryName: '资料分类',
              children: data.dataBase,
              moduleCode: 'dataBase',
              language: data.meeting[0].language,
              parentId: null,
              id: 3,
            },
          ];
          // }
          cacheTrees.concat(data.meeting);
          state.treeMeetingData = [
            {
              categoryName: '日程分类',
              children: data.meeting,
              moduleCode: 'meeting',
              language: data.meeting[0].language,
              parentId: null,
              id: 1,
            },
          ];
          state.treeData = [
            ...state.treeMeetingData,
            ...state.treeNewsData,
            ...state.treeDataBaseData,
          ];
          // state.expandedKeys = cacheTrees.map(i => i.id);
          state.expandedKeys = [1];
          console.log(state.expandedKeys);
        })
        .finally(() => {
          state.spinning = false;
        });
    };
    const handleClickAdd = () => {
      // activeKey.value = 'one';
      // state.selectedKeys = [];
      // const checked = state.checkedKeys.checked;
      if (!form.id) {
        message.info('请至少选择一条数据');
        return;
      }
      if (form.id === 2 || form.id === 1 || form.id === 3) {
        //删除根目录新增
        delete form.id;
      }
      state.formData = { ...form };
      if (state.formData.parentId === null || state.formData.parentId === 0) {
        delete state.formData.categoryLevel;
        delete state.formData.children;
      }
      state.formData.sort = 0;
      if (state.selectedKeys) {
        state.visibleModal = true;
        if (state.formData.id) {
          state.formData.parentId = state.formData.id;
          delete state.formData.id;
        }
        state.formData.parentName = '根结点';
        // resetFields();
        // form.parentId = form.parentId || null;
        // form.parentName = getTreeItem(
        //   cacheTrees,
        //   item => item.id === checked[0]
        // )?.categoryName;
      } else {
        // resetFields();
        state.visibleModal = true;
      }
    };
    const handleSave = () => {
      if (!form.id) {
        message.info('请至少选择一条数据');
        return;
      }
      validate().then(() => {
        let type = 'update';
        menuApi[type](`menu:${type}`, toRaw(form)).then(() => {
          message.success('操作成功');
          // handleClickAdd();
          state.visibleModal = false;
          initTreeData();
        });
      });
    };
    const handleSaveAdd = () => {
      formRef.value.validate().then(() => {
        console.log(state.formData);
        let type = 'add';
        menuApi[type](`menu:${type}`, toRaw(state.formData)).then(() => {
          message.success('操作成功');
          // handleClickAdd();
          resetFields();
          state.visibleModal = false;
          initTreeData();
        });
      });
    };

    // watch(
    //   [() => form.path, () => form.accessWay],
    //   ([path, accessWay], [, prevAccessWay]) => {
    //     if (path && !path.startsWith('/')) {
    //       form.path = `/${path}`;
    //     }
    //     switch (accessWay) {
    //       case 'builtin':
    //         form.component ||
    //         (form.component = `${form.path.substring(1)}/index.vue`);
    //         break;
    //       default:
    //         if (
    //           prevAccessWay === 'builtin' &&
    //           !form.component.startsWith('http')
    //         ) {
    //           form.component = '';
    //         }
    //         break;
    //     }
    //   }
    // );
    initTreeData();
    return {
      icons,
      activeKey,
      componentType,
      visible,
      ...toRefs(state),
      form,
      validateInfos,
      formRef,
      moduleCode,
      gridHeight: document.body.clientHeight - 616,
      handleClickAdd,
      handleOk: () => {
        handleSaveAdd();
      },
      handleCancel: () => {
        state.visibleModal = false;
      },
      handleSave,
      handleSaveAdd,
      handleClickDelete: () => {
        // const ids = state.checkedKeys.checked;

        if (form.id) {
          Modal.confirm({
            title: `确定要删除选中的数据吗？`,
            icon: createVNode(icons.ExclamationCircleOutlined),
            okType: 'danger',
            onOk: () => {
              // todo 删除接口 classificationApi.delete
              menuApi.delete('org.delete', form.id).then(() => {
                message.success('操作成功');
                state.checkedKeys.checked = [];
                resetFields();
                initTreeData();
              });
            },
          });
        } else {
          message.info('请至少选择一条数据');
        }
      },
      handleChange: () => {
        state.treeData = [];
        initTreeData();
      },
      handleSelect: (keys, { selected, selectedNodes }) => {
        if (selected) {
          state.selectedKeys = keys;
          state.selectedKeys = keys;
          const node = selectedNodes[0].props.dataRef;
          // state.parentId = node.parentId;
          Object.assign(form, node, {
            parentName:
              node.parentId === null
                ? '根结点'
                : getTreeItem(cacheTrees, item => item.id === node.parentId)
                    ?.categoryName,
          });
          if (!node.id) {
            delete form.id;
          }
        }
      },
      handleExpand: (keys, info) => {
        console.log('keys:', keys);
        console.log('info:', info);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-wrapper {
  margin: 16px;
}
.main-tree {
  padding: 20px;
  border-radius: 8px;
  //box-shadow: @box-shadow-base;
  background: #ffffff;
}
.main-content {
  padding-top: 16px;
  border-radius: 8px;
  background: #ffffff;
  //box-shadow: @box-shadow-base;
}
.iss-tree {
  height: calc(100vh - 210px);
  overflow-y: auto;
  .iss-tree-item {
    display: flex;
    align-items: center;
  }
}
.iss-form {
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: calc(100vh - 154px);
  overflow-y: auto;
  :deep(.ant-form-item-label) {
    width: 80px;
  }
}
.iss-btn {
  margin-left: 80px;
}
.iss-tab {
  :deep(.ant-tabs-small-bar) {
    .ant-tabs-tab {
      padding-top: 0;
    }
  }
  :deep(.ant-tabs-bar) {
    margin: 0px 0px 0px 20px;
  }
  :deep(.ant-tabs-tabpane) {
    padding-bottom: 10px;
  }
}
.parent {
  display: flex;
}

.left {
  width: 35%;
  margin-left: 20px;
}
:deep(.iss-tree) {
  height: auto;
}
</style>
